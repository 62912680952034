/* eslint-disable */
<template>
<div class="font-default">
  <!-- Mobile Menu -->
  <div class="absolute top-0 md:hidden w-full">
    <button @click="toggleMenu" type="button" class="bg-teal-800 inline-flex items-center justify-center p-2 text-warmGray-100 hover:text-white focus:outline-none" aria-controls="mobile-menu" aria-expanded="false">
      <svg :class="isMenuOpen ? 'hidden' : 'block'" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
      </svg>
      <svg :class="isMenuOpen ? 'block' : 'hidden'" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    <div class="md:hidden" id="mobile-menu">
      <div v-if="isMenuOpen" class="bg-teal-800 text-coolGray-100 w-1/2">
        <div class="w-full p-2" :class="this.$store.state.currentPage==='Home' ? 'bg-teal-700' : 'bg-transparent'">
          <router-link tag="button" to="/">
            <button @click="toggleMenu" class="w-full text-left outline-none focus:outline-none">Home</button>
          </router-link>
        </div>
        <div class="w-full p-2" :class="this.$store.state.currentPage==='About' ? 'bg-teal-700' : 'bg-transparent'">
          <router-link tag="button" to="/about">
            <button @click="toggleMenu" class="w-full text-left outline-none focus:outline-none">About</button>
          </router-link>
        </div>
        <div class="w-full p-2" :class="this.$store.state.currentPage==='Book' ? 'bg-teal-700' : 'bg-transparent'">
          <router-link tag="button" to="/book">
            <button @click="toggleMenu" class="w-full text-left outline-none focus:outline-none">Book Online</button>
          </router-link>
        </div>
        <div class="w-full p-2" :class="this.$store.state.currentPage==='Contact' ? 'bg-teal-700' : 'bg-transparent'">
          <router-link tag="button" to="/contact">
            <button @click="toggleMenu" class="w-full text-left outline-none focus:outline-none">Contact</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- Header -->
  <div class="md:px-4 pt-4">
    <div class="text-center flex-row">
      <div class="md:text-6xl text-3xl md:py-4 text-teal-700">Dr. Jiwa</div>
      <div class="md:text-3xl text-xl md:mb-10">Registered Osteopath</div>
    </div>
    <!-- Desktop Menu -->
    <div class="hidden md:flex bg-teal-800 justify-center text-2xl whitespace-nowrap text-warmGray-100 shadow-lg">
      <router-link class="menu-button" :class="this.$store.state.currentPage==='Home' ? 'bg-teal-700' : 'bg-transparent'" to="/">Home</router-link>
      <router-link class="menu-button" :class="this.$store.state.currentPage==='About' ? 'bg-teal-700' : 'bg-transparent'" to="/about">About</router-link>
      <router-link class="menu-button" :class="this.$store.state.currentPage==='Book' ? 'bg-teal-700' : 'bg-transparent'" to="/book">Book Online</router-link>
      <router-link class="menu-button" :class="this.$store.state.currentPage==='Contact' ? 'bg-teal-700' : 'bg-transparent'" to="/contact">Contact</router-link>
    </div>
  </div>
</div>
  <router-view />
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    const isMenuOpen = ref(false);

    return { isMenuOpen };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
})
</script>

<style>
body {
  background-color: #f5f3ee;
}

@media screen and (min-width: 768px){
  body {
    width: 1080px;
    margin: auto !important;
  }
}
</style>