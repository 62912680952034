/* eslint-disable */
<template>
  <div class="md:mx-auto md:p-4 p-2">
    <div>
      <!-- What is osteopathy -->
      <div class="md:flex md:justify-evenly font-default shadow-lg bg-white p-2">
        <div>
          <div class="text-teal-700 text-xl md:text-3xl">
            What is osteopathy?
          </div>
          <div class="md:mt-10 font-sans">
            Osteopathy focuses on the musculoskeletal system and the way in which this inter-relates with the body as a whole. It combines scientific knowledge of anatomy and phsyiology, as well as clinical methods of investigation.
            Osteopaths diagnose and treat faults which occur because of injury, stress, or disease. By enabling the musculoskeletal system to work as efficiently as possible, the body is encouraged to restore itself.​
          </div>
        </div>
        <img src="/images/jiwa-spine.jpg"/>
      </div>
      <!-- Who do osteopaths treat -->
      <div class="mt-5 md:flex md:justify-evenly font-default shadow-lg bg-white p-2">
        <img src="/images/skull.jpg"/>
        <div class="md:ml-2">
          <div class="text-teal-700 text-xl md:text-3xl">
            What do osteopaths treat?
          </div>
          <div class="md:mt-5 font-sans">
            Musculoskeletal problems affect many people, and can occur at any time in your life. Most patients tend to be middled-aged, or more advanced in years, when fitness levels aren't what they used to be and there is a greater risk of injury from physical activity.
            But there are also a large number of younger people suffering with sports or work related injuries that seek treatment from osteopaths. 
          </div>          
        </div>
      </div>
      <!-- Visiting an osteopath -->
      <div class="mt-5 md:flex md:justify-evenly font-default shadow-lg bg-white p-2">
        <div>
          <div class="text-teal-700 text-xl md:text-3xl">
            Visiting an osteopath
          </div>
          <div class="md:mt-10 font-sans">
            When you visit an osteopath for the first time, a full medical history will be taken, and you will be given an examination whereby any points of weakness, excessive strain, or specific injury will be identified. 
            The physical assessment will be considered alongside lifestyle factors such as work and leisure activities to enable a full diagnosis and creation of a suitable treatment plan.​
            For further information, <router-link to="/contact"><span class="text-teal-700">contact us</span></router-link>.
            <div class="mt-5 flex justify-center">
              <a href="https://cpd.osteopathy.org.uk/wp-content/uploads/2023/04/Visiting-an-osteopath-poster-pdf.pdf"><img class="max-w-none" src="https://cpd.osteopathy.org.uk/wp-content/uploads/2023/04/Patient-poster.png"/></a>
            </div>

          </div>          
        </div>                
      </div>
      <div class="text-teal-700 font-bold text-center text-sm mt-10 w-full italic font-default">
        Osteopathy is a holistic approach, and any treatment will be geared specifically toward your recovery
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.commit("setCurrentPage", "About");
  }
}
</script>
