
import { createStore } from "vuex";

const store = createStore({
    state() {
        return {
            currentPage: "",
        };
    },
    mutations: {
        setCurrentPage(state, page) {
            state.currentPage = page;
        },
    }
});

export default store;