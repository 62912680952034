/* eslint-disable */
<template>
  <div class="md:mx-auto md:p-4 p-2">
    <div class="hidden text-center text-xl md::text-2xl py-4 text-teal-700">
        <a href="tel:+447974 406635">For home visits, please call on 07974 406635</a>
    </div>
    <div class="text-center">
        If you encounter any problems using the online booking service, please feel free to call us on 07974406635 to arrange a booking.
    </div>
    <div>
        <iframe id="schedulista-widget-00" src="https://www.schedulista.com/schedule/drhuseinjiwaosteopath?mode=widget" allowtransparency="true" frameborder="0" scrolling="yes" width="100%" height="900px">

        </iframe>        
    </div>
  </div>
</template>

<script>
export default {
    mounted() {
        this.$store.commit("setCurrentPage", "Book");
    }
}
</script>

<style>

</style>