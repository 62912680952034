import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home';
import About from '../views/About';
import Book from '../views/Book';
import Contact from '../views/Contact';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: "/book",
    name: "Book Online",
    component: Book
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

export default router
