/* eslint-disable */
<template>
  <div class="md:mx-auto md:p-4 px-2">
      <!-- Visiting the clinic -->
      <div>
        <div class="font-default text-2xl mb-4">
          Visiting the clinic
        </div>
        <div class="md:flex md:justify-evenly">
            <img class="mb-2 md:mb-0" src="/images/clinic_1.jpg"/>
            <img src="/images/clinic_2.jpg"/>
        </div>
        <!-- Instructions -->
        <div class="pt-4">
          You will need to sign a GDPR form<p/>          
          <br/>
          <div class="mt-4">
            In case if you need a chaperone present during the examination and treatment then please bring either a friend or a family member.<br/>
            If you are using GP surgery entrance, turn on your right and a few yards down the corridor you will see the practice sign on your left.<br/>
            If you are using the pharmacy entrance, as you arrive at the premises head straight through the corridor. At the  end  of the corridor, you  will see a sign on your right.<br/>
            A car park is located at the back of the building. Please be advised the car park has a car recognition system in operation. Bring your car registration number with you to register.<br/>            
          </div>
        </div>         
        <div class="mt-4 map-style" id="map"/>
        <div class="mt-4 text-xs font-default font-bold">
          Village Health Point, 
          <p/>97 Church Street, 
          <p/>Werrington, 
          <p/>Peterborough, 
          <p/>Cambridgeshire,
          <p/>PE4 6QF
        </div>
        <div class="mt-2 text-xs font-default font-bold text-teal-700">
          <a href="tel: +447974 406635">07974 406635</a>
        </div>
      </div>
  </div>
</template>

<script>
import { Loader } from 'google-maps';
export default {
    async mounted() {
      this.$store.commit("setCurrentPage", "Contact");
      // Load the Google Maps script
      const loader = new Loader('AIzaSyAEV8NWJlLZ01aYkhBi2VIpUmIFQUFzqjc');
      loader.load().then(function (google) {
        const location = { lat: 52.61414, lng: -0.27213 };
        const map = new google.maps.Map(document.getElementById('map'), {
          center: location,
          zoom: 18,
        });
        const marker = new google.maps.Marker({
          position: location,
          map,
          title: "Dr. Husein Jiwa"
        });
      });
    },
    data() {
      return {
        location: null,
      }
    },
    methods: {      
    }
}
</script>

<style>
.map-style {
  width: 300px;
  height: 300px;
  margin: auto;
}

@media screen and (min-width: 768px){
  .map-style {
    height: 500px;
    width: 500px;
    margin-left: 0px;
  }
}

</style>