<template>
  <div class="p-2">
    <router-link tag="button" to="/book">
        <button class="font-default text-xs p-4 rounded-md bg-teal-800 text-white ring-1 ring-white hover:bg-teal-700 focus:outline-none">Book Online Now</button>
    </router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>