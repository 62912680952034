<template>
    <div class="md:mx-auto md:px-4 font-default">
        <!-- Image and overlay text -->
        <div class="md:relative">
            <img class="main-image md:px-0 px-2 shadow-lg md:rounded-none" src="/images/main.jpg" />
            <img class="h-16 top-20 left-5 absolute md:visible md:h-52 md:top-0 md:left-10" src="/images/apm-logo.png" />
            <div class="image-text md:absolute p-4 md:p-0 text-sm font-bold font-sans">
                Dr Husein Jiwa is a professional osteopath registered with the General Osteopathic Council and has been practising in both Peterborough and London for over 20 years.
                <p/><br/>He received his training at the British College of Osteopathic Medicine.
                <p/><br/>His qualifications include:
                <p/>Bachelor of Medicine
                <p/>Diploma in Osteopathy
                <p/>Diploma in Naturopathy
                <div class="mb-4"/>
                <BookingButton />
            </div>
        </div>
        <!-- Covid -->
        <div class="p-4 md:px-0">
            <div class="text-3xl text-center text-teal-700 mb-4">
                Learn about our Covid-19 Safety Measures
            </div>
            <div v-for="measure in safetyMeasures" :key="measure" class="text-sm font-sans px-10">
                <li>{{ measure }}</li>
            </div>
            <div class="py-2 text-sm font-sans">
                This virus appears to be spreading easily, and is thought to spread mainly from person-to-person through people who are in close contact with one another (within about 1.5m) or through respiratory droplets produced when an infected person coughs or sneezes.
                <br/><span class="font-bold">If you are showing any COVID-19 symptoms or are in contact with family members or work colleagues who have COVID -19 symptoms, please call to cancel your appointment.</span>
            </div>
            <!-- Notifications -->
            <div class="py-4 text-bold md:text-2xl text-teal-700">
                <div class="underline hover:no-underline">
                    <router-link to="/contact">
                        For details about visiting the clinic, please click here
                    </router-link>
                </div>
                <div class="underline hover:no-underline">
                    <a href="https://drive.google.com/file/d/1jfkbOp6wptb9t_ntVMPkabIV8pzymutk/view?usp=sharing" target="_blank">
                        For important information for patents, please click here
                    </a>
                </div>
            </div>
            <!-- Small image and info -->
            <div class="md:flex">
                <img src="/images/jiwa-small.jpg"/>
                <div class="md:w-1/2 md:self-center md:ml-10">
                    <span class="text-teal-700 font-bold">Dr. Husein Jiwa</span> is a professional osteopath registered with the <span class="text-teal-700 font-bold">Osteopathic Council</span> and has been practising in both Peterborough and London for over 20 years. 
                </div>
            </div>
            <!-- Reviews -->
            <div class="md:flex md:flex-row-reverse">
                <div class="md:flex md:flex-col items-center">
                    <div>
                        <a href="https://www.google.com/maps/place/Dr+Jiwa+Osteopath/@52.6141341,-0.2747027,17z/data=!3m1!4b1!4m6!3m5!1s0x4877f14a55c73b0b:0x1de19b93bf034c32!8m2!3d52.6141309!4d-0.2721278!16s%2Fg%2F1ptyxsw27?hl=en-US" target="_blank"><img class="w-32 mb-4 mx-auto" src="/images/google-five-stars.png" /></a>
                    </div>
                    <div>
                        <a href="https://www.yell.com/biz/dr-h-jiwa-registered-osteopath-peterborough-9271444/" target="_blank"><img class="w-32 mx-auto" src="/images/yell.png" /></a>
                    </div>
                </div>
                <div class="md:w-11/12">
                    <div v-for="review in reviews" :key="review.attributer" class="text-sm font-sans">
                        <div class="py-2">
                            <span class="italic">"{{ review.review }}"</span> <span class="text-teal-700">- <a :href="review.url" target="_blank">{{ review.attributer }}</a></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-2/3 mx-auto mt-4 flex justify-between">
                <div>
                    <a class="text-teal-700 font-bold" href="https://www.google.com/maps/place/Dr+Jiwa+Osteopath/@52.6141309,-0.2743165,17z/data=!4m18!1m9!3m8!1s0x4877f14a55c73b0b:0x1de19b93bf034c32!2sDr+Jiwa+Osteopath!8m2!3d52.6141309!4d-0.2721278!9m1!1b1!16s%2Fg%2F1ptyxsw27!3m7!1s0x4877f14a55c73b0b:0x1de19b93bf034c32!8m2!3d52.6141309!4d-0.2721278!9m1!1b1!16s%2Fg%2F1ptyxsw27">
                        Read more 5 star reviews on Google
                    </a>
                </div>
                <div>
                    <a class="text-teal-700 font-bold" href="https://www.yell.com/biz/dr-h-jiwa-registered-osteopath-peterborough-9271444/">
                        Read more 5 star reviews on Yell
                    </a>
                </div>
            </div>
            <div class="hidden text-center text-xl md::text-2xl py-4 text-teal-700">
                <a href="tel:+447974 406635">For home visits, please call on 07974 406635</a>
            </div>
        </div>
    </div>
</template>

<script>
import BookingButton from '../components/Booking-Button';

export default {
    components: {
        BookingButton
    },
    mounted() {
        this.$store.commit("setCurrentPage", "Home");
    },
    data() {
        return {
            safetyMeasures: [                
                "Hand sanitiser and face mask will be provided for patient’s use, a requirement of treatment.",                
                "Those in a high risk demographic (aged over 70, pregnant, or immune compromised) are advised to only seek treatment if absolutely necessary.",
                "All hard surfaces, seats and couch are sprayed with disinfectant between every patient.",
                "All commonly handled items (door handles, card machine, keyboards etc) are disinfected between every patient."
            ],
            reviews: [
                {
                    review: "Dr Jiwa is very professional and he explained to me what was wrong with my back. I was in a lots of pain on my first visit! On my second visit, my back pains were much better! I highly recommend him.",
                    attributer: "Maria Morse",
                    url: "https://goo.gl/maps/3QYCC3CfAcdZGdZJ6"
                },
                {
                    review: "Excellent service, I had neck and shoulder muscle pain and Dr Jiwa has worked wonders and with some exercises it has gone. Thank you very much.",
                    attributer: "Roger Harris",
                    url: "https://goo.gl/maps/TQVtw2gmpZVcEfy36"
                },
                {
                    review: "Have been attending Dr Jiwa's Osteopathy Practice for over 20 years. Have no hesitation  in highly recommending him to anyone seeking osteopathic treatment.",
                    attributer: "John Davis",
                    url: "https://goo.gl/maps/6t5dhBdRj7aw86zw7"
                },
                {
                    review: "If you are wondering whether to book an appointment I highly recommend Dr Jiwa. His personable nature will put you at ease as you explain why you are there. His treatments are effective and my pain levels were reduced after my first visit. He is following COVID-19 guidelines.",
                    attributer: "Susan Stevenson",
                    url: "https://www.yell.com/reviews/user/SusanStevenson-775",
                },
                {
                    review: `Dr. Jiwa was welcoming and had an air of calmness and understanding which gave me confidence in his capacity to respond to my needs and concerns. He sought my agreement to all parts of the treatment...  I have progressed from gnawing ache through seering breath taking agony to infrequent manageable twinges to feelings of rejuvenation. My guide and mentor on this journey has been Dr Husein Jiwa to whom I am most grateful. His comment that I was "Good for my age" was more helpful than another dose of analgesics!`,
                    attributer: "Bryan Davis (via 10to8)",
                    url: ""
                },
            ],
        }
    }
}
</script>

<style>
@media screen and (min-width: 768px){
    .image-text {
        right: 100px;
        top: 50px;
        width: 300px;
    }
    .main-image {
        width: 1080px;
    }
}

</style>